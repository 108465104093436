.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.blue-btn {
  background: linear-gradient(180deg, #1b286e 0%, #2040eb 100%);
  box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.1);
}

.cover-background {
  height: 100vh;
  background-image: url('../public/static_images/landing_background.svg');
  background-size: 100%;
  background-position-y: 12rem;
  background-repeat: no-repeat;
}

.image-background {
  background-image: url('../public/static_images/contract_background.svg');
  background-size: 70%;
  background-repeat: no-repeat;
  background-position-y: 5rem;
  background-position-x: 40%;
}

.App-link {
  color: #61dafb;
}

.modal-header {
  background-color: #88305f;
  height: auto;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-between;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #88305f;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #792954;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #611b3f;
}

.according-class {
  background-color: #88305f1f !important;
  color: #49454f;
  font-weight: 600;
  font-size: 14px;
  border: none !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  border-radius: 18px !important;
  padding: 0 16px;
}
.clause-heading {
  padding: 0 !important;
}

.according-class::before {
  background-color: transparent !important;
}

.clause-present-heading {
  background: linear-gradient(
    90deg,
    rgba(255, 248, 223, 0.95) 0%,
    rgb(255, 255, 255) 98.92%
  );
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.01);
  padding: 2% 15px;
  color: #4d4d4d;
  font-weight: 400;
  font-family: Inter;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.clause-heading .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}
.clause-sub-heading {
  background: linear-gradient(
    89.98deg,
    rgb(255, 241, 253) 0.66%,
    rgb(255, 255, 255) 99.99%
  );
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.01);
  padding: 1.5% 15px;
  color: #4d4d4d;
  font-weight: 400;
  font-family: Inter;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.clause-div {
  padding: 2% 15px;
  border: 1px solid #f2f2f2;
  background-color: white !important;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.01);
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
}

.clause-div-basic {
  padding: 2% 15px;
  border: 1px solid #f2f2f2;
  background-color: white !important;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.01);
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.clause-data {
  color: #88305f;
  cursor: pointer;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 0 30px 0 0px;
}
.clause-data-basic {
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
}

.active-color.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px;
}
.active-color1.MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px;
}

.edit-clause-select .title {
  color: #000;
}

.compare-diff-string {
  word-break: break-all;
  font-size: 14px;
}

.compare-diff-string ins {
  margin-left: 4px;
}

/* search highlight  */
.hlt1 {
  font-weight: bold;
  background-color: #ffedad;
}

.according-class.reminderCardDeleted {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.according-class.reminderCardDeleted .MuiAvatar-circular {
  background: rgba(0, 0, 0, 0.15);
}
