body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.loader-bar {
  animation: bar-loading infinite 1s;
  background: #996c84;
  width: 1rem;
  height: 4rem;
  margin: 0 0.2rem;
}

.loader-bar:nth-child(1) {
  animation-delay: 0.8s;
}
.loader-bar:nth-child(2) {
  animation-delay: 1.4s;
}
.loader-bar:nth-child(3) {
  animation-delay: 1.8s;
}
.loader-bar:nth-child(4) {
  animation-delay: 0.6s;
}
.loader-bar:nth-child(5) {
  animation-delay: 1s;
}
.loader-bar:nth-child(6) {
  animation-delay: 0.5s;
}
.loader-bar:nth-child(7) {
  animation-delay: 1.2s;
}

@keyframes bar-loading {
  25% {
    height: 1rem;
    background-color: lightgrey;
  }
  75% {
    height: 5rem;
    background-color: lightgrey;
  }
}

.sentences-container {
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    0px -2px 4px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  width: 93%;
  padding: 30px;
  line-height: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
}
.annotate-icn-container {
  background: linear-gradient(180deg, #ffef5a 0%, #fbce2f 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 35px;
  width: 35px;
  opacity: 0.8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.annotate-icn-container:hover {
  border: 1px solid #88305f;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.annotate-small-icn-container {
  background: linear-gradient(180deg, #ffef5a 0%, #fbce2f 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  opacity: 0.8;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}
.annotate-small-icn-container:hover {
  border: 1px solid #88305f;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.highlight-sentences div:hover {
  cursor: pointer;
  outline: 3px solid #fbce2f;
}
.linked-para-count {
  font-family: Muli;
  font-weight: bold;
  height: 19px;
  width: 19px;
  display: flex;
  justify-content: center;
  border-radius: 25px;
  align-items: center;
  color: #88305f;
  /* background: #ffedad; */
}
.phrase-selector::selection {
  background: #fbce2f;
}

.clause-selector::selection {
  background: #fff0bc;
}
